
import Vue from 'vue'
import { CsSquare } from '@consteel/csuetify'
import {
  getShowedCompareStates,
  setCurrentCamera,
  setShowedCompareStates,
} from '@/store/modelViewer.store'

export default Vue.extend({
  name: 'HistoryCompareFooter',
  components: {
    CsSquare, //TODO
  },
  computed: {
    selectedCompareStates: {
      get() {
        return getShowedCompareStates()
      },
      set(newVal: string[]) {
        setShowedCompareStates(newVal)
        setCurrentCamera()
        //drawCompareView(true, false) //TODO
      },
    },
  },
})
